import {inject} from 'aurelia-framework';
import Backend from "../backend";
import {redirectToActiveSeoKey} from "../util/redirect-to-active-seo-key";
import {MetaTags} from "../util/meta-tags";

@inject(Backend)
export class SupplierScreen {
  backend;

  constructor(backend) {
    this.backend = backend;
  }

  canActivate(params, routeConfig) {
    return this.backend.SupplierIndexQueryHandler_handle({supplierIdOrSeoKey: params.supplierIdOrSeoKey})
        .then(result => {
          this.supplier = result.suppliers[0];

          // SEO: Title
          routeConfig.navModel.setTitle(this.supplier.name + ' | ' + routeConfig.title);

          // SEO: Meta tags
          MetaTags.setMetaTags_rememberToCallCleanOnUnbind(this.supplier.ingress);

          // SEO: handle seokey
          return redirectToActiveSeoKey(params, routeConfig, 'supplierIdOrSeoKey', this.supplier.seoKey);
        });
  }
}
