import Backend from 'backend';
import {inject} from 'aurelia-framework';
import {Redirect, Router} from 'aurelia-router';
import {UserInfoProvider} from "../util/user-info-provider";
import {RMatomo} from "../util/r-matomo";

@inject(Backend, Router, UserInfoProvider)
export class InstantCheckoutRoute {
  backend;
  router;
  userInfoProvider;

  constructor(backend, router, userInfoProvider) {
    this.backend = backend;
    this.router = router;
    this.userInfoProvider = userInfoProvider;
  }

  canActivate(params) {
    // NOTE: Only authenticated users will arrive here

    // If user has no subscription he can't buy a sub product directly.
    let subscriptionId = params.subscriptionId || this.userInfoProvider.getInfo().subscriptionId;
    if (!subscriptionId) {
      return new Redirect('my-page');
    }

    // If productId is specified, buy it...
    if (params.checkoutProductId) {
      return this.backend.CheckoutSubProductCommandHandler_handle({
                      subscriptionId: subscriptionId,
                      items: [{
                        productId: params.checkoutProductId,
                        incrementQuantity: 1,
                        initialOneTimeValue: true
                      }]
                    })
        .then(() => {
          // Track page before we redirect because it will not be auto tracked by router:success
          RMatomo.trackPageView(window.location.toString(), 'Instant checkout');

          // Go to my-page
          return new Redirect('my-page');
        });
    }
    // ... otherwise go right to my page
    else {
        return new Redirect('my-page');
    }
  }
}
