import Backend from 'backend';
import {ShoppingCart} from './shopping-cart';
import {customElement, inject, observable} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {SpecialPriceCalculator} from "./specialPriceCalculator";

@customElement('week-extra-component')
@inject(Element, ShoppingCart, Backend, Router, SpecialPriceCalculator)
export class WeekExtraComponent {
  element;
  backend;
  shoppingCart;
  router;
  specialPriceCalculator;

  @observable({changeHandler: 'choosenProductsChanged'})
  chosenProducts = []; // Bound to sup product component
  initValues = {};

  @observable({changeHandler: 'valueOfGoodsChanged'})
  valueOfGoods;

  constructor(element, shoppingCart, backend, router, specialPriceCalculator) {
    this.element = element;
    this.shoppingCart = shoppingCart;
    this.backend = backend;
    this.router = router;
    this.specialPriceCalculator = specialPriceCalculator;
  }

  attached() {
    return this.backend.CmsFragmentPublicQueryHandler_handle({items: [
          {cmsFragmentKey: "checkout_flow__week_extra__section1"}
        ]})
      .then(result => {
        this.cmsFragments = result;
      });
  }

  // Switched from attached() to bind() after having issues with loading initValues after going back and forth between checkout steps.
  bind() {
    // Get shopping cart info
    let info = this.shoppingCart.getInfo();
    this.crateName = info.crateProduct.name;

    // Put each in the init values
    this.initValues = {};
    info.weekExtraProducts.forEach(cartSubProduct => {
      this.initValues[cartSubProduct.productId] =
        {
          quantity: cartSubProduct.quantity,
          everyTime: !cartSubProduct.oneTime
        };
    })
  }

  valueOfGoodsChanged() {
    // Total price
    this.calculatedPrice = this.specialPriceCalculator.calculateSumIncVat(this.shoppingCart.getInfo().crateProduct.productId, this.valueOfGoods);
  }

  choosenProductsChanged() {
    this.addChosenProductsToCart();
  }

  addChosenProductsToCart() {
    // Store in shopping cart
    let self = this;
    this.shoppingCart.atomic(cart => {
      cart.clearWeekExtraAndCustomizationProducts();
      self.chosenProducts
        // The SubProductComponent allows quantity zero if initValues was non zero because it should be possible to remove items,
        // but that is not necessary here since we are working with client-side data and we just cleared the product list.
        .filter(product => product.quantity > 0)
        .forEach(product => {
            cart.addWeekExtraProducts(product.productId, product.name, product.quantity, !product.everyTime);
        });
      });
  }
}
