import Backend from '../backend';
import {ShoppingCart} from './checkout-flow/shopping-cart';
import {inject, observable, TaskQueue} from 'aurelia-framework';
import {Redirect, Router} from 'aurelia-router';

@inject(ShoppingCart, Backend, Router, TaskQueue)
export class SingleProductCatalogScreen {
  element;
  backend;
  shoppingCart;
  router;
  taskQueue;

  chosenProducts = []; // Bound to sub product component
  initValues = {};

  @observable({changeHandler: 'valueOfGoodsChanged'})
  valueOfGoods;

  crateChoiceModalComponentRef;

  constructor(shoppingCart, backend, router, taskQueue) {
    this.shoppingCart = shoppingCart;
    this.backend = backend;
    this.router = router;
    this.taskQueue = taskQueue;
  }

  activate() {
    return Promise.all([
      this.backend.CmsFragmentPublicQueryHandler_handle({items: [
            {cmsFragmentKey: "single_product_catalog__section1"},
          ]})
        .then(result => this.cmsFragments = result),
    ]);
  }

  bind() {
    // Debug: useful for development of modal content
    // this.crateChoiceModalRef.open();
  }

  canActivate() {
    return this.shoppingCart.load()
      .then(() => {
        // Has user already selected a crate?
        let info = this.shoppingCart.getInfo();
        if (info.crateProduct.productId) {
          // If so, go to checkout flow.
          return new Redirect('checkout-flow', {step: 'first'});
        } else {
          // if not, return 'yes, we can activate this screen'
          return true;
        }
      });
  }

  valueOfGoodsChanged(newValue, oldValue) {
    // Skip initial value assignment
    if (oldValue === undefined) {
      return;
    }

    // Show crate choice modal
    this.taskQueue.queueMicroTask({
      call: () => {
        this.crateChoiceModalComponentRef.show(this.chosenProducts);
      }
    });
  }
}
