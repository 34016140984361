import {bindable, customElement, inject} from 'aurelia-framework';
import Backend from "../backend";
import {RMatomo} from "../util/r-matomo";

@customElement('featured-products-slider-component')
@inject(Element, Backend)
export class FeaturedProductsSliderComponent {
  element;
  backend;
  scrollContainerRef;

  @bindable() subscriptionId;
  @bindable() disableAutoCheckout; // Set to true to avoid this component postinc checkout command to backend. Event will still be raised and caller can perform checkout itself.

  featuredProducts;
  scrollCompleted;

  constructor(element, backend) {
    this.element = element;
    this.backend = backend;
  }

  attached() {
    this.backend.SubProductCategorizedQueryHandler_handle({})
      .then(result => {
        this.featuredProducts = result.products
          .filter(product => product.isFeaturedOnSubProducts)
          .sort((p1,p2) => p1.featuredProductsSort - p2.featuredProductsSort);
      })
      .catch(error => {
        // Mute because my-page can work fine without this component

        // Log to backend
        this.backend.RecordFrontendLogCommandHandler_handle({logData: 'error while loading subproducts in featured-products-slider-component: ' + JSON.stringify(error)})
      });
  }

  buy(product) {
    this.checkoutSubProduct(product)
      .then(() => RMatomo.trackGoalConversion(RMatomo.GOAL_ID__FEATURED_PRODUCT_CHECKOUT))
      .then(() => this.raiseEvent(product))
  }

  checkoutSubProduct(product) {
    if (this.disableAutoCheckout === true) {
      return Promise.resolve();
    } else {
      return this.backend.CheckoutSubProductCommandHandler_handle({
        subscriptionId: this.subscriptionId,
        items: [{
          productId: product.productId,
          incrementQuantity: 1,
          initialOneTimeValue: true
        }]
      });
    }
  }

  raiseEvent(product) {
    this.element.dispatchEvent(new CustomEvent('product-checked-out', {
      detail: {
        productId: product.productId,
        product: product
      },
      bubbles: true
    }));
  }

  scrollLeft() {
    this.slide('left');
  }

  scrollRight() {
    this.slide('right');
  }

  slide(direction, scrollStep = 100, scrollTotal = 256, intervalMs = 50) {
    let scrollCompleted = 0;
    let slideVar;
    slideVar = setInterval(() => {
      if (direction === 'left') {
        this.scrollContainerRef.scrollLeft -= scrollStep;
      } else {
        this.scrollContainerRef.scrollLeft += scrollStep;
      }
      scrollCompleted += scrollStep;
      if (scrollCompleted >= scrollTotal) {
        window.clearInterval(slideVar);
      }
    }, intervalMs);
  }
}
